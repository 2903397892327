.myPicture {
  height: 40vmin;
  pointer-events: none;
  border-radius: 40vmin;
}

.Page {
  margin-top: 5vmin;
  text-align: center;
  background-color: white;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Title { 
  font-size: 8vmin;
  font-weight: bold;
  font-family: source-code-pro, Menlo, Monaco,
    monospace;
}

.Icons {
  margin-top: 3vmin;
  font-size: 3vmin;
  align-items: center;
  font-family: source-code-pro, Menlo, Monaco,
  monospace;
}

.Icon { 
  height: 6vmin;
  margin: 1vmin;
  margin-left: 2vmin;
  margin-right: 2vmin;
}

.Text {
  margin-top: 3vmin;
  margin-left: 5vmin;
  margin-right: 5vmin;
  font-size: 3vmin;
  text-align: left;
  font-family: source-code-pro, Menlo, Monaco,
  monospace;
}

