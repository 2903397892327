.Page {
    margin-top: 5vmin;
    text-align: center;
    background-color: white;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}
  
.Title { 
    font-size: 8vmin;
    font-weight: bold;
    font-family: source-code-pro, Menlo, Monaco,
      monospace;
    margin-bottom: 2vmin;
}
  
.Text {
    margin-top: 3vmin;
    margin-left: 5vmin;
    margin-right: 5vmin;
    font-size: 3vmin;
    text-align: left;
    font-family: source-code-pro, Menlo, Monaco,
    monospace;
}
  